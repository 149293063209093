import React, { useState } from "react";
import { send } from "emailjs-com";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButton,
  IonGrid,
  IonCol,
  IonRow,
  IonInput,
  IonItem,
  IonLabel,
  useIonAlert,
} from "@ionic/react";
import "./Home.scss";
import Logo from "../assets/logo.png";
import Around from "../assets/around.png";
import MoneySave from "../assets/money-save.png";
import Vacations from "../assets/vacations.png";
import Market from "../assets/market.png";
import Assistant from "../assets/assistant.png";
import Image1 from "../assets/IMAGEN_1.jpg";
import Image2 from "../assets/IMAGEN_2.jpg";
import Image3 from "../assets/IMAGEN_3.jpg";
import Image4 from "../assets/IMAGEN_4.jpg";

const Home: React.FC = () => {
  const { gtag } = require("ga-gtag");

  const [toSend, setToSend] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
  });
  const [presentAlert] = useIonAlert();

  function sendForm() {
    send("service_abbn5ij", "template_gwmsqls", toSend, "JxSzArh_UTO9rqyYn")
      .then((response) => {
        presentAlert({
          header: "¡Datos enviados!",
          message:
            "Tus datos han sido enviados, nos pondremos en contacto contigo",
          buttons: ["Ok"],
        }).finally(() => {
          setToSend({
            name: "",
            email: "",
            phone: "",
            city: "",
          });
        });

        gtag_report_conversion('http://vacationclubargentina.com/home');
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  }
  
  function gtag_report_conversion(url:any) {
    var callback = function () {
      if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    gtag('event', 'conversion', {
        'send_to': 'AW-10940575799/fG_7CN-k7NMDELfg7-Ao',
        'event_callback': callback
    });
    return false;
  }

  function onSetName(event: any) {
    setToSend({ ...toSend, name: event.target.value });
  }
  function validateEmail(event: any) {
    setToSend({ ...toSend, email: event.target.value });
  }

  function onSetPhone(event: any) {
    setToSend({ ...toSend, phone: event.target.value });
  }

  function onSetCity(event: any) {
    setToSend({ ...toSend, city: event.target.value });
  }

  function validateForm() {
    return (
      toSend.name !== "" &&
      toSend.email !== "" &&
      toSend.phone !== "" &&
      toSend.city !== ""
    );
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar><div className="subtitle-logo">
          <img className="img-logo" src={Logo} alt="Logo" />
          Broker Exclusivo de Marriott Vacation Club</div>
          {/* <IonButtons slot="primary">
            <IonButton fill="solid" color="primary">
              Ver teléfono
            </IonButton>
            <img
              className="logo-whats"
              slot="end"
              src={whats}
              alt="whatsapp logo"
            />
          </IonButtons> */}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div className="card-container m-auto" id="form">
          <IonGrid>
            <IonRow>
              <IonCol sizeXs="12" sizeMd="6">
                <div className="title-card">
                  <div className="title-card-main-label text-center">
                    ¿Queres vacaciones de lujo todos los años?
                  </div>
                  <div className="title-card-secondary-label text-center">
                    Invierta lo que hoy gasta en vacacionar y sea dueño para
                    toda la vida.
                  </div>
                </div>
              </IonCol>
              <IonCol sizeXs="12" sizeMd="6">
                <div className="card-form-container">
                  <div className="title-form">Dejanos tus datos</div>
                  <IonItem lines="none" class="item-no-borders">
                    <IonLabel position="floating">Nombre:</IonLabel>
                    <IonInput
                      value={toSend.name}
                      onIonChange={(e) => onSetName(e)}
                    ></IonInput>
                  </IonItem>
                  <IonItem lines="none" class="item-no-borders">
                    <IonLabel position="floating">Correo Electrónico:</IonLabel>
                    <IonInput
                      inputMode="email"
                      value={toSend.email}
                      onIonChange={(e) => validateEmail(e)}
                    ></IonInput>
                  </IonItem>
                  <IonItem lines="none" class="item-no-borders">
                    <IonLabel position="floating">Teléfono:</IonLabel>
                    <IonInput
                      inputMode="tel"
                      onIonChange={(e) => onSetPhone(e)}
                      value={toSend.phone}
                    ></IonInput>
                  </IonItem>
                  <IonItem lines="none" class="item-no-borders">
                    <IonLabel position="floating">Ciudad:</IonLabel>
                    <IonInput
                      value={toSend.city}
                      onIonChange={(e) => onSetCity(e)}
                    ></IonInput>
                  </IonItem>
                  <div className="btn-submit-container">
                    <IonButton disabled={!validateForm()} onClick={sendForm}>
                      Enviar
                    </IonButton>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <div className="icons-info">
          <IonGrid className="m-auto color-stone">
            <IonRow>
              <IonCol>
                <div className="interest-item-img">
                  <img src={Around} alt="Around the world" />
                </div>
                <div className="interest-item-text">
                  + de 10.000 complejos y experiencias por el mundo
                </div>
              </IonCol>
              <IonCol>
                <div className="interest-item-img">
                  <img src={MoneySave} alt="Money save" />
                </div>
                <div className="interest-item-text">
                  Inverti para viajar toda la vida
                </div>
              </IonCol>
              <IonCol>
                <div className="interest-item-img">
                  <img src={Vacations} alt="Vacations" />
                </div>
                <div className="interest-item-text">
                  Especialistas en vacaciones
                </div>
              </IonCol>
              <IonCol>
                <div className="interest-item-img">
                  <img src={Market} alt="Market" />
                </div>
                <div className="interest-item-text">
                  Más de 23 años en el mercado
                </div>
              </IonCol>
              <IonCol>
                <div className="interest-item-img">
                  <img src={Assistant} alt="Assistance" />
                </div>
                <div className="interest-item-text">Asesoría personalizada</div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>

        <div className="how-it-works-section-container m-auto">
          <div className="title-section text-center">¿Cómo funciona?</div>
          <IonRow>
            <IonCol sizeXs="12" sizeSm="5">
              <img src={Image1} alt="Vacacion con hijo" />
            </IonCol>
            <IonCol sizeXs="12" sizeSm="7" className="label-info color-stone flex-centered">
              Cuando se convierte en propietario de Vacation Club, recibe una
              asignación anual de puntos. (Dependiendo de su monto invertido).
              <br /> <br />
              Estas son las “monedas” que puede utilizar para convertir sus
              vacaciones soñadas en una realidad para usted y sus seres
              queridos.
            </IonCol>
          </IonRow>
        </div>

        <div className="vacation-points-section m-auto">
          <div className="title-section text-center">Puntos Vacation Club</div>
          <div className="row-flex">
            <div className="info-container">
              <div className="img-container">
                <img src={Image2} alt="Vacacion relajante" />
              </div>
              <div className="text-container color-stone text-center">
                Los Puntos Vacation Club le brindan opciones incomparables para
                que pueda relajarse, explorar y descubrir su estilo de vida
                vacacional ideal de cada año. De por vida.
              </div>
            </div>

            <div className="info-container">
              <div className="img-container">
                <img src={Image3} alt="Vacacion Egipto" />
              </div>
              <div className="text-container color-stone text-center">
                Puede depositar los Puntos Vacation club adquiridos este año
                para usarlos el próximo año, o tomar prestados del próximo año
                para realizar una reserva este año. Incluso puedes hacer ambas
                cosas a la vez.
              </div>
            </div>

            <div className="info-container">
              <div className="img-container">
                <img src={Image4} alt="Vacacion Paris" />
              </div>
              <div className="text-container color-stone text-center">
                ¡Podes agregar Puntos Vacation club en cualquier momento!
              </div>
            </div>
          </div>
        </div>

        <div className="start-experience-section m-auto">
          <div className="title-section text-center">
            Comenzá a vivir la experiencia Vacation Club
          </div>
          <div className="text-container color-stone text-center">
            Con miles de opciones para elegir, hay un mundo de oportunidades de
            vacaciones disponibles para usted.
            <br />
            <br />
            Nosotros le vamos a a diseñar un paquete personalizado de Puntos
            Vacation Club en función de sus necesidades. A medida que sus
            gustos, familia y preferencias cambien con el tiempo, también
            cambiará su definición de las vacaciones "ideales" y estaremos allí
            para recibirlo con una oferta diversa de experiencias de alta
            calidad en todo el mundo.
          </div>
          <div className="btn-container">
            <a href="/home#form">
              <IonButton>Quiero más información</IonButton>
            </a>
          </div>

          <div className="footer-video"></div>
        </div>

        <div className="footer">
          <video className="footer-video" src="/ARGENTINA.mp4" controls>
            Your browser does not support the video tag.
          </video>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
